import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import headLogo350x75px from "../images/headLogo350x75px.webp";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation(); // Get current language from i18n

  const currentLanguage =
    i18n.language || localStorage.getItem("language") || "en"; // Default to 'en' if language is not set
  const getPath = (path) => {
    let newURL =
      currentLanguage === "en" ? `${path}` : `/${currentLanguage}${path}`;
    return newURL;
  };
  return (
    <>
      {/* footer section start*/}

      <hr className="hrStyle" />
      <div className="container-fluid footerBg3 pt-5">
        <div className="container pb-5">
          <div className="container footerFoot">
            <div className="row">
              <div className="col-6 leftSideFooterDiv">
                <div>
                  {/* <img src={logo} className='logoImg'/> */}
                  <p className="logoImg">
                    <Link to={`/${currentLanguage}`}>
                      <img src={headLogo350x75px} alt="Logo" />
                    </Link>
                  </p>
                  <p className="textUL">{t("footer.mainTitle")}</p>
                </div>
              </div>
              <div className="col-6 rightSideFooterDiv">
                <div className="row text-center g-1 linkFootROwDiv">
                  <div className="col-6 text-start d-flex flex-column ps-5 linkFootDiv">
                    <h3 className="h3InFoot">{t("footer.subTitle1.head")}</h3>
                    <a href={getPath("/pricing")} className="linkIfoot">
                      {t("footer.subTitle1.title1")}
                    </a>
                    <a href={getPath("/about-us")} className="linkIfoot">
                      {t("footer.subTitle1.title2")}
                    </a>
                    <a href={getPath("/contact-us")} className="linkIfoot">
                      {t("footer.subTitle1.title3")}
                    </a>
                  </div>
                  <div className="col-6 text-start d-flex flex-column ps-5 linkFootDiv">
                    <h3 className="h3InFoot">{t("footer.subTitle2.head")}</h3>
                    <a
                      href={getPath("/terms-conditions")}
                      className="linkIfoot"
                    >
                      {t("footer.subTitle2.title1")}
                    </a>
                    <a href={getPath("/refund-policy")} className="linkIfoot">
                      {t("footer.subTitle2.title2")}
                    </a>
                    <a href={getPath("/privacy-policy")} className="linkIfoot">
                      {t("footer.subTitle2.title3")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 developedByDiv">
                <p>
                  2024 ©{" "}
                  <a
                    href="https://digitrix.agency/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Digitrix Agency
                  </a>
                  , All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer section end  */}
    </>
  );
};

export default Footer;
