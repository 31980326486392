import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css"; // Make sure to import your CSS file
import AirAsia from "../images/airAsia.webp";
import Emirates from "../images/emirates.webp";
// import PhilippineAirlines from "../images/philippineAirlines.webp";
import Thai from "../images/thai.webp";
// import SriLankanAirlines from "../images/srilankanAirlines.webp";
import BritishAirways from "../images/britishAirways.webp";
import QatarAirways from "../images/qatarAirways.webp";
import TurkishAirlines from "../images/turkishAirlines.webp";
import MalaysiaAirlines from "../images/malaysiaAirlines.webp";
import AirIndia from "../images/airIndia.webp";
import SingaporeAirlines from "../images/singaporeAirlines.webp";
import DeltaAirLines from "../images/deltaAirLines.webp";
import UnitedAirlines from "../images/unitedAirlines.webp";
import Lufthansa from "../images/lufthansa.webp";
import AmericanAirlines from "../images/americanAirlines.webp";
import Qantas from "../images/qantas.webp";
import Ryanair from "../images/ryanair.webp";

const FlightLogoSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000, // Slower, smooth transition between slides
    slidesToShow: 5,
    slidesToScroll: 1, // Moves one slide at a time for smoothness
    autoplay: true,
    autoplaySpeed: 0, // Continuous loop without stops
    pauseOnHover: false, // Keeps moving even when hovered
    cssEase: "linear", // Smooth, continuous slide effect
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const logos = [
    AirAsia,
    Emirates,
    UnitedAirlines,
    Lufthansa,
    Thai,
    DeltaAirLines,
    BritishAirways,
    QatarAirways,
    TurkishAirlines,
    MalaysiaAirlines,
    AirIndia,
    SingaporeAirlines,
    Qantas,
    AmericanAirlines,
    Ryanair,
  ];

  return (
    <div className="carousel-wrapper">
      <div className="slider-container">
        <div className="row sliderRow">
          <div className="col sliderCol">
            <Slider {...settings}>
              {logos.map((logo, index) => (
                <div key={index}>
                  <img
                    src={logo}
                    className="sliderImg"
                    alt={`flight logo ${index + 1}`}
                    width="150"
                    height="56"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightLogoSlider;
